
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@media only screen and (max-width: 768px) {
    .nav-link{
        display: block!important;
    }
  }

@media (min-width: 768px) {
    .nav-link{
        display: inline!important;
    }
  }




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.RoutesContainer {
  min-height:100vh;
  display:flex;
  background-color: #0b0b0b;
}


.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  border-radius: 25px;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  background-image: linear-gradient(#0499AA, #79C143);
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 26px;
  background-image: linear-gradient(#0499AA, #79C143);
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 45px;
  background-image: linear-gradient(#0499AA, #79C143);
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}


.loader 
{
 position: fixed;
 left: 0px;
 top: 0px;
 width: 100vw;
 height: 100vh;
 z-index: 9999;
 background: rgb(0,0,0,0.5);
}

.loader .loading-text{
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -75px;
    margin-top: 60px;
    width: 200px;
    color: #FFF;
    text-shadow: #000000 3px 5px 2px;
}

.loader .lds-facebook{
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
}

#moving_div{
  position: fixed;
  z-index:100000;
  /* display:none; */
  color: white;
  background:#000;
  display:inline-block;

}

.sticky-top{
  /* background: #000; */
  background-image: linear-gradient(to right, #17954c , #0d8493);
}

.page-pad{
    padding: 15px;
    background: #FFF;
    width: 100%;
    color: #242424;
    text-align:left;
}

.loginBody{
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
  height: 100vh;
}

.loginContainer{
  margin-top:80px;
  min-width:460px;
  max-width:460px;
  border-radius:6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #FFF;
  padding:25px;
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    1px 1px 4px 0 rgba(0,0,0,0.15);  /* Firefox 3.5 - 3.6 */
  box-shadow:         1px 1px 4px 0 rgba(0,0,0,0.15);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.loginTitle{
  padding-top:25px;
  font-size: 2em;
  font-weight:bold;
}

.loginForm{
  margin-top:25px;
}

.loginInput{
  margin-top:25px;
  padding: 5px;
}

  .inp {
    position: relative;
    width: 100%;
    max-width: 280px;
    margin:0px;
  }
  .inp .label {
    position: absolute;
    top: 14px;
    left: 0;
    font-size: 16px;
    color: #9098a9;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all 0.2s ease;
  }
  .inp .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: #07f;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.15s ease;
  }
  .inp input {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 12px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #c8ccd4;
    background: none;
    border-radius: 0;
    color: #223254;
    transition: all 0.15s ease;
  }
  .inp input:hover {
    background: rgba(34,50,84,0.03);
  }
  .inp input:not(:placeholder-shown) + span {
    color: #5a667f;
    transform: translateY(-30px) scale(0.75);
  }

  .inp input:focus {
    background: none;
    outline: none;
  }

  .inp input:focus + span {
    color: #07f;
    transform: translateY(-30px) scale(0.75);
  }
  .inp input:focus + span + .border {
    transform: scaleX(1);
  }

  .loginButton{
    margin-top:25px;
    margin-bottom:25px;
    max-width:280px;
    width:100%;
    display:block;
  }
  
  .loginSmallLink{
    background:none;
    color:inherit;
    border:none; 
    padding:0!important;
    font: inherit;
    /*border is optional*/
    border-bottom:1px solid #444; 
    cursor: pointer;  
    max-width:120px;
    width:100%;
    margin-left:5px;
    margin-right:5px;
  }

  .loginSmallLink:hover{
    background:none;
    color:blue;
    border-bottom:1px solid blue; 
  }  

  .loginContainer form{

  }


  /* Button 4 */
.loginContainer .btn{
  max-width:280px;
  width:100%;
  margin-top:20px;
  margin-bottom:20px;
  background: #2ecc71!important;
}

.btn-4 {
	border-radius: 50px;
	border: 3px solid #fff;
	color: #fff;
	overflow: hidden;
}

.btn-4:active {
	border-color: #17954c;
	color: #17954c;
}

.btn-4:hover {
	background: #24b662;
}

.btn-4:before {
	position: absolute;
	height: 100%;
	font-size: 125%;
	line-height: 3.5;
	color: #fff;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.btn-4:active:before {
	color: #17954c;
}


/* Button 4c */
.btn-4c:before {
	left: 70%;
	opacity: 0;
	top: 0;
}

.btn-4c:hover:before {
	left: 80%;
	opacity: 1;
}

.icon-arrow-right:before {
	content: "\e00d";
}

.effect {
  margin-top:25px;
  margin-bottom:25px;
  width:280px;
  display:block;
  text-align: center;
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  /* background-color: - add your own background-color */
  font-family: 'Roboto', sans-serif;
  /* put your font-family */
  font-size: 18px;
  padding: 20px 0px;
  border-radius: 6px;
  overflow: hidden;
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(24,154,170,1) 0%, rgba(120,192,67,1) 100%);
}

.btn-grad {background-image: linear-gradient(to right, #00d2ff 0%, #3a7bd5 51%, #00d2ff 100%)}
.btn-grad:hover { background-position: right center; }


/* effect-4 styles */
.effect.effect-4 {
  transition: all 0.2s linear 0s;
}
.effect.effect-4:before {
  content: "\f178";
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 30px;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  transition: all 0.2s linear 0s;
}
.effect.effect-4:hover {
  text-indent: -9999px;
}
.effect.effect-4:hover:before {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  text-indent: 0;
}

.failedLogin{
  color: #FFF;
  margin:auto;
  padding: 50px

}

a.tip{
    position:relative;
    color: #FFF;
    }
    a.tip:hover, a.tip:visited{
    text-decoration:none;
    color: #FFF;
    }
    a.tip:after{
    content: attr(tip);
    font-family:Arial, Helvetica, sans-serif;
    font-size:90%;
    line-height:1.2em;
    color:#fff;
    padding:5px 10px;
    -moz-border-radius:6px;
    -webkit-border-radius:6px;
    border-radius:6px;
    background:#c00d3f;
    background:-webkit-linear-gradient(#c00d3f, #7b0a2a);
    background:-moz-linear-gradient(#c00d3f, #7b0a2a);
    background:-o-linear-gradient(#c00d3f, #7b0a2a);
    background:-ms-linear-gradient(#c00d3f, #7b0a2a);
    background:linear-gradient(#c00d3f, #7b0a2a);
    -moz-box-shadow: 3px 3px 4px rgba(0,0,0, .65);
    -webkit-box-shadow: 3px 3px 4px rgba(0,0,0, .65);
    box-shadow: 3px 3px 4px rgba(0,0,0, .65);
    position:absolute;
    top:27px;
    left:-10px;
    display:none;
    }
    a.tip:before{
    z-index:1000;
    position:absolute;
    content:"";
    top:15px;
    left:0px;
    border-right:7px transparent solid;
    border-left:7px transparent solid;
    display:none;
    }
    a.tip:hover{
    z-index:1000;
    position:relative;
    color:#FFF;
    cursor: default;
    }
    a.tip:hover:after{
    display:block;
    }
    a.tip:hover:before{
    display:block;
    }

