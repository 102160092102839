.Users{
    padding: 10px;
    width: 100%;
    background: #FFF;
    text-align:left;
}

h2{
    color: #242424;
    font-size: 1.25em;
}