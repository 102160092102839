.Home{
    width:100%;
    height: 100%;
    min-height: 100%;
}
.Home .lander {
    padding: 0px 0;
    text-align: center;
    width:100%;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }

  #map{
      position:fixed;
      top:56px;
      height: calc(100vh - 56px);
      height: -o-calc(100vh - 56px); /* opera */
      height: -webkit-calc(100vh - 56px); /* google, safari */
      height: -moz-calc(100vh - 56px); /* firefox */
      width:100vw;
      background-color: #FFF;
  }

  .sidebar-header h3{
    padding-left:10px;
    margin-left:0px;
    text-align: left;
    font-size: 1.25em;
    top:10px;
    position:absolute;
  }

  .slide-pane__content{
    padding: 0px!important;
  }

 .asset_details{
   padding:10px;
   width: 100%;
   font-size: 0.8em;
   text-align: left;
   border: 1px thin #aeaeae;
   background: rgb(245, 245, 245);
 
 }


 .slide-pane{
  background: rgb(245, 245, 245)!important;
 }

  .green{
  color:green;
 }

 #addpickup{
  width: 100%;
  padding: 10px!important;
  border-width: 0px 0px 1px 0px;
  border-radius: 0px!important;
  color: #FFF;
 }


 .optional{
   background: #16485f;
   color: #FFF;
 }

 .mButton{
   width: 100%;
   margin-bottom: 25px;
   text-align: left;
 }

  .wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
  }
  
  #dismiss {
    cursor: pointer;
    width: 35px;
    height: 35px;
    position: absolute;
    /* top right corner of the sidebar */
    top: 10px;
    right: 10px;
  }

  .side-bar{
    position:relative;
    top:56px;
  }

  .asset_icon{
    width: 150px;
    text-align:center;
  }

  .asset_icon img{
    max-width: 80%;
  }

  .flex-container {
    display: flex;
  }

  .asset_half{
    width:50%;
  }

  .asset_details .asset_half{
    padding-top: 10px;
  }

  .asset_details .flex-container{
    margin-bottom:20px;
  }

  .asset_id{
    font-size:1.5em;
  }




  .asset_icon_overview{
    width: 75px;
    text-align:center;
  }

  .asset_icon_overview span svg{
    width: 50px;
    height: 50px;
  }

  .asset_right_overview{
    width: 75%;
    font-size:0.8em;
  }
  
  .asset_overview_block{
    background: #efefef;

    margin-bottom: 10px;
    margin-top: 10px;
    padding-top:10px;
  }

.detailButton{
  width: 45x;
  height: 35px;
  background: #003ab6;
  color: #FFF;
  position: fixed;
  display: block;
  top: 65px;
  right:10px;
  z-index:9998;

}
.detailButtonHome{
    width: 45x;
    height: 35px;
    background: #1b9602;
    color: #FFF;
    position: fixed;
    display: block;
    top: 115px;
    right:10px;
    z-index:9998;
  
  }

.slide-pane_from_right .slide-pane__close {
  transform: rotate(180deg)
}

.assetslist{
  margin-bottom: 75px;
}

.searchbar{
  padding:10px;
  background: #000;
  border-bottom:1px solid #aeaeae;
}

.sidebar_notice{
  padding:10px;
}

.viewbar{
  background-color: #fefefe;
  display: flex;
}

.viewbar-segment{
  width: 50%;
  height: 100%;
  background-color:#fefefe;
  text-align: center;
  vertical-align: center;
  margin: auto;
  padding:5px;
}

.viewbar-segment:hover{
  background-color: #efefef;
  cursor: pointer;
}

.viewbar-selected{
  color: #FFF;
  background-color: #000;
}

.viewbar-selected:hover{
  background-color: #000;
  cursor: default!important;
}

.panel-display{
  display:block;
}

.panel-hide{
  display:none;
}

.dropbar{
  padding:10px;
  background: #000;
  border-bottom:1px solid #aeaeae;
}

.dropbar label{
  color: #FFF;
}

.dropbar .form-check{
  margin-top:20px;
  margin-bottom:20px;
}

.dropbar button{
  margin-bottom:25px;
}

.dropbar button{
  width: 100%;
}

.star-svg{
  background-color:#ff0;
}
.star-svg path{
  fill: #828282;
}

.jd{
  margin-bottom:10px;
  margin-top:10px;
  padding: 10px;
  border-radius: 15px;
  background: #fefefe;
  color: #000;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.jdCustomer{
  font-weight: bold;
  font-size: 1.25em;
}

.jdNotes span.jdTitle{
  display: block;
  font-weight: bold;
  margin-top:25px;
}


.jdDriverDetails span.jdTitle{
  display: block;
  font-weight: bold;
}


.jd div{
  padding-bottom: 20px;
}


.white{
  color:#FFF;
 }
 
 .green{
  color:green;
 }

 .red{
  color:red;
 } 
 
 .mButton-green{
   border-radius: 25px;
   background: green;
   color: #000;
   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
   padding: 5px;
   color: #FFF;
 }

 .mButton-red{
  border-radius: 25px;
  background: yellow;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  padding: 5px;
  color: #000;
} 


.jdNotes button{
  border-radius: 25px;
  margin-top:5px;
  margin-right:5px;
}

button:focus {outline:0;}


.notify{
  margin-top:10px;
  width:100%;
  background:#0074d9;
  padding: 5px!important;
  border-width: 0px 0px 1px 0px;
  border-radius: 0px!important;
  color: #FFF;
}

select.notify,input.notify{
  font-weight: bold;
}

.notifyTitle{
  display:block;
  font-weight: bold;
  text-transform: uppercase;
}

.notifyTitle{
  border-radius: 25px;
  margin-top:15px;
  margin-right:5px;
  padding-left:15px;
  padding-right:15px;
  background-color: rgb(0, 116, 217);
  color: white;
}

.notifyOpen{
  display:block;
  margin-top: 10px;
  background:rgb(0, 116, 217);;
  padding: 10px;
  border-radius: 10px;
  color: #FFF!important;
}

.notifyClose{
  display:none;
}

.toggleContent-open{
  display: block;

  transition: all 0.25s ease-out;
}

.toggleContent-closed{
  display: none;
  transition: all 0.25s ease-out;
}

.notifyDriverBlock{
  padding: 10px;
  background: #383838;
  color: #FFF;
}

.notifyButton{
  width: 100%;
  margin-top: 15px;
  border-radius: 10px;
  padding: 10px;
}

textarea.notify{
  height: 150px;
  background: #FFF;
  color: #000;
}

@-webkit-keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}


.maintenanceblock{
    padding-bottom:15px;
}

.assetOverdue{
    padding-bottom:100px;   
}

.minday{
    width: 45px;
    text-align: center;
}


.red_alert{
    color: red;
}

#loadingblock{
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 32px;
    height: 32px;
    z-index: 1000;
    padding: 8px;
}

.quickadddrop{
    text-align: center;
    font-size:1em;
    border-top: 1px solid #aeaeae;
    padding-top:10px;
}

.quickaddtitle{
    font-weight: bold;
    margin-bottom: 10px;
}

.addDropInput{
    margin-top:10px;
    height: 35px;
    width: 100%;
    padding: 10px;
}

.addDropButton{
    height: 35px;
    width: 100%;
    margin-top:15px;
}

.quickcoord{
    padding-bottom:10px;
}